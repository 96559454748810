//styles
import "./Unauthorised.css"

// components
import { Link } from "react-router-dom"

export default function unauthorised(props) {
    const { issue } = props

    return (
        <div id="unauthorised-container">
            <div className="toph"><h1>Unauthorised Access</h1></div>
            <Link to="../../">
                <svg className="App-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1278 1080">
                    <defs>
                        <style>
                            {`.cls-4{fill:#ff9}.cls-5{fill:#acedfc}.cls-10{fill:#68af2f}.cls-12{opacity:0.61}.cls-13{fill:#fff}.cls-16{fill:#5a5e5e}`}
                        </style>
                        <clipPath id="clip-path">
                            <circle cx="604.59" cy="550.14" r="189.55" fill="none" />
                        </clipPath>
                        <clipPath id="clip-path-2">
                            <circle className="cls-1" cx="996" cy="319.44" r="81.34" />
                        </clipPath>
                    </defs>
                    <g id="Layer_2" data-name="Layer 2">
                        <rect width="1278" height="1080" fill="none" id="background" />
                        <g opacity="0.85" id="stars">
                            <polygon className="cls-4" points="377 296 386.19 312.81 403 322 386.19 331.19 377 348 367.81 331.19 351 322 367.81 312.81 377 296" />
                            <polygon className="cls-5" points="378.5 410 383.98 420.02 394 425.5 383.98 430.98 378.5 441 373.02 430.98 363 425.5 373.02 420.02 378.5 410" />
                            <polygon className="cls-4" points="386 713 395.19 729.81 412 739 395.19 748.19 386 765 376.81 748.19 360 739 376.81 729.81 386 713" />
                            <polygon className="cls-4" points="773 768 782.19 784.81 799 794 782.19 803.19 773 820 763.81 803.19 747 794 763.81 784.81 773 768" />
                            <polygon className="cls-5" points="498.5 785 503.27 793.73 512 798.5 503.27 803.27 498.5 812 493.73 803.27 485 798.5 493.73 793.73 498.5 785" />
                            <polygon className="cls-5" points="893 441 902.55 458.45 920 468 902.55 477.55 893 495 883.45 477.55 866 468 883.45 458.45 893 441" />
                            <polygon className="cls-5" points="615.5 784 621.69 795.31 633 801.5 621.69 807.69 615.5 819 609.31 807.69 598 801.5 609.31 795.31 615.5 784" />
                            <polygon className="cls-4" points="536 836 539.54 842.46 546 846 539.54 849.54 536 856 532.46 849.54 526 846 532.46 842.46 536 836" />
                            <polygon className="cls-4" points="818 413 821.54 419.46 828 423 821.54 426.54 818 433 814.46 426.54 808 423 814.46 419.46 818 413" />
                            <polygon className="cls-5" points="877 722 881.24 729.76 889 734 881.24 738.24 877 746 872.76 738.24 865 734 872.76 729.76 877 722" />
                            <polygon className="cls-5" points="697 822 701.24 829.76 709 834 701.24 838.24 697 846 692.76 838.24 685 834 692.76 829.76 697 822" />
                            <polygon className="cls-4" points="845 640 849.6 648.4 858 653 849.6 657.6 845 666 840.4 657.6 832 653 840.4 648.4 845 640" />
                            <polygon className="cls-4" points="284.5 503 291.04 514.96 303 521.5 291.04 528.04 284.5 540 277.96 528.04 266 521.5 277.96 514.96 284.5 503" />
                            <polygon className="cls-4" points="374.5 604 379.27 612.73 388 617.5 379.27 622.27 374.5 631 369.73 622.27 361 617.5 369.73 612.73 374.5 604" />
                            <polygon className="cls-4" points="491.5 326 496.27 334.73 505 339.5 496.27 344.27 491.5 353 486.73 344.27 478 339.5 486.73 334.73 491.5 326" />
                            <polygon className="cls-4" points="679 285 688.19 301.81 705 311 688.19 320.19 679 337 669.81 320.19 653 311 669.81 301.81 679 285" />
                            <polygon className="cls-5" points="555.5 255 559.92 263.08 568 267.5 559.92 271.92 555.5 280 551.08 271.92 543 267.5 551.08 263.08 555.5 255" />
                            <polygon className="cls-5" points="803.5 276 810.75 289.25 824 296.5 810.75 303.75 803.5 317 796.25 303.75 783 296.5 796.25 289.25 803.5 276" />
                            <polygon className="cls-4" points="933 580 940.07 592.93 953 600 940.07 607.07 933 620 925.93 607.07 913 600 925.93 592.93 933 580" />
                        </g>
                        <g id="moon2">
                            <circle cx="995.66" cy="319.34" r="81.34" fill="#8a9091" />
                            <circle className="cls-16" cx="986.63" cy="285.27" r="22.25" />
                            <circle className="cls-16" cx="937.96" cy="304.74" r="6.95" />
                            <circle className="cls-16" cx="969.94" cy="360.35" r="20.86" />
                            <circle className="cls-16" cx="1027.64" cy="329.07" r="11.82" />
                            <circle className="cls-16" cx="1019.3" cy="370.78" r="7.65" />
                            <circle className="cls-16" cx="1039.46" cy="285.27" r="4.17" />
                            <g clipPath="url(#clip-path-2)">
                                <circle cx="1074.12" cy="319.44" r="81.34" fill="#202121" id="moon-shadow2" opacity="0.53" />
                            </g>
                        </g>
                        <g id="Layer_1-2" data-name="Layer 1">
                            <g id="earth">
                                <circle cx="604.59" cy="550.14" r="189.55" fill="#25aece" />
                                <g clipPath="url(#clip-path)">
                                    <g id="land">
                                        <path d="M760.55,545.48a49.08,49.08,0,0,0-11.44-14l-8.45-6.61a28.85,28.85,0,0,0-16.56-5.24l-40.6,2.06a67.06,67.06,0,0,1-18.17-2.08l-17.24-4.93a29.93,29.93,0,0,0-17.4,1.19l-33.13,14.58c-4.67,2.05-9.63,7.74-11,12.65l-8.93,31.27c-1.4,4.9.22,12,3.61,15.84L601,612.42a26.44,26.44,0,0,0,15.4,7.62s35.12-.31,36,16.45c.21,4.13-4.11,21.74-4.11,21.74.55,5.07-3.27,13.11-5.09,17.87l-3.62,16.75a12.29,12.29,0,0,0,4.36,13.86l15.71,10.64a11.61,11.61,0,0,0,14.18-1.4L732.86,656a38.46,38.46,0,0,0,8.56-15.64l5.91-26A73.46,73.46,0,0,1,754,597.3l12.75-22c2.55-4.41,2.78-11.75.5-16.31Z" fill="#7f6545" />
                                        <path d="M685.83,353.64a9.44,9.44,0,0,1-4.25,12.49s-30.64,15.32-34.35,16.25S611.68,392,611.68,392a54.09,54.09,0,0,0-16.2,8.2L583.12,410c-4,3.17-8.73,1.85-10.52-2.92l-1.83-4.89c-1.79-4.77-1.79-11.81,0-15.63s-.76-8.13-5.65-9.57l-29.49-8.67c-4.89-1.44-6.27-5.86-3.06-9.82,0,0,29.39-36.36,32.18-36.36s102,10.18,102,10.18c5.07.52,11,4.7,13.26,9.29Z" fill="#ccc6be" />
                                        <path className="cls-10" d="M480.82,357.74a27.23,27.23,0,0,0,15.27,8l32.55,3.55c5.07.55,6.66,4.3,3.54,8.33L514.8,400.07a72.64,72.64,0,0,0-8.9,16l-12.1,32.67a40.52,40.52,0,0,1-10.39,14.57l-32,26.25A65.86,65.86,0,0,0,440.53,501c-2,3.06-4,1.4-4.3-3.69l-.66-10.23A11.28,11.28,0,0,0,425.74,477l-1-.1a13.87,13.87,0,0,0-13,7.57l-.73,1.63c-2.09,4.65-4.24,4.3-4.76-.77l-7.35-70.54c-.53-5.07,1.95-12.21,5.5-15.86l52.89-54.37a8.36,8.36,0,0,1,12.51.38Z" />
                                        <path className="cls-10" d="M409.84,638.66c1.58-4.85,4.32-12.72,6.08-17.51l6.57-17.83a8.46,8.46,0,0,1,11.63-4.83l17.45,8a60,60,0,0,1,14.9,10.5l26.9,27.53c3.56,3.65,6.25,10.79,6,15.88l-.85,15.78A82.2,82.2,0,0,0,500,694.52l4.37,19.9a14,14,0,0,1-5.91,13.9l-25,15.33a12.17,12.17,0,0,1-14.37-1.79l-55.66-57.16c-3.56-3.65-5.17-10.61-3.59-15.45Z" />
                                        <path className="cls-10" d="M752.86,397.22a13.55,13.55,0,0,0-14.3-4.43L691.22,409a53.85,53.85,0,0,0-15.64,9.23l-15.92,14.43c-3.78,3.42-6.3,10.36-5.62,15.41l.28,2a14.87,14.87,0,0,1-6.8,13.78l-16.35,9.34a12.69,12.69,0,0,0-5.64,13.55l1.67,6.2c1.33,4.92,4.6,11.24,7.28,14s8.47,3,12.87.42l14.12-8.26c4.4-2.57,8.54-8.81,9.2-13.87l.38-2.94a11.94,11.94,0,0,1,10.44-9.9l5.62-.43c5.08-.39,12.25,2.18,15.93,5.71l9.8,9.41c3.68,3.53,9.56,9.45,13.06,13.15l3.93,4.15a36.59,36.59,0,0,0,15.4,8.86l48.7,11.5c5,1.17,8.9-2,8.76-7.14l-1.32-46.35a34.56,34.56,0,0,0-5.8-16.7Z" />
                                    </g>
                                    <g id="clouds" opacity="0.46">
                                        <g className="cls-12">
                                            <path className="cls-13" d="M450.61,620.71c-23.39,10.13-31.48,29.68-18,43.44s45.19,22,70.41,18.26l21.38-3.16c25.22-3.72,25.83-12.61,1.36-19.76L510,654.9c-24.47-7.15-23.89-16.29,1.28-20.32l73.46-11.76c25.17-4,27.87-18,6-31.15l-37.28-22.34c-21.87-13.1-59.72-17.79-84.12-10.41l-35.48,10.72c-24.4,7.37-23.52,14.19,1.95,15.14l43.63,1.63c25.47,1,27.17,10,3.78,20.15Z" />
                                        </g>
                                        <g className="cls-12">
                                            <path className="cls-13" d="M456.63,497.79c-24.15,8.15-23.27,15.65,2,16.67s64.8-6.92,87.92-17.65l18.8-8.72c23.12-10.73,51-30.66,62-44.3s-.91-25.65-26.38-26.71l-8.42-.35C567,415.67,562,401.19,581.29,384.54l4-3.42c19.3-16.65,16.66-20.52-5.88-8.61L450.91,440.43c-22.53,11.91-20.21,23.59,5.17,26l27.28,2.54c25.38,2.36,26.38,11,2.23,19.11Z" />
                                        </g>
                                        <g className="cls-12">
                                            <path className="cls-13" d="M622.66,546.43C600,555.1,600.85,569.75,624.61,579l5.38,2.09c23.76,9.24,58,8.46,76.1-1.74s49.32-31.4,69.39-47.11l4-3.1c20.07-15.72,15.64-28.94-9.85-29.38l-40.8-.71c-25.49-.44-32.09-16-14.67-34.64l51.6-55.14c17.42-18.61,13.84-23-7.94-9.78L625.66,479.73c-21.79,13.23-22.09,30.11-.68,37.5S645.35,537.77,622.66,546.43Z" />
                                        </g>
                                        <path className="cls-13" d="M610,774.69c-14.34,21.07-11.55,39,6.21,39.9S659,809,671.69,800.27,707,772.73,721.91,758.5s11.83-40.09-6.8-57.49l-.64-.6C695.84,683,693,659,708.27,647s7.32-17.21-17.56-11.64l-44.57,10c-24.87,5.57-30.22,17.92-11.87,27.44s21.62,34.55,7.27,55.63Z" opacity="0.63" />
                                    </g>
                                </g>
                            </g>
                            <g id="moon">
                                <circle cx="995.66" cy="319.34" r="81.34" fill="#8a9091" />
                                <circle className="cls-16" cx="986.63" cy="285.27" r="22.25" />
                                <circle className="cls-16" cx="937.96" cy="304.74" r="6.95" />
                                <circle className="cls-16" cx="969.94" cy="360.35" r="20.86" />
                                <circle className="cls-16" cx="1027.64" cy="329.07" r="11.82" />
                                <circle className="cls-16" cx="1019.3" cy="370.78" r="7.65" />
                                <circle className="cls-16" cx="1039.46" cy="285.27" r="4.17" />
                                <g clipPath="url(#clip-path-2)">
                                    <circle cx="1074.12" cy="319.44" r="81.34" fill="#202121" id="moon-shadow" opacity="0.53" />
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </Link>
            <h1>Error</h1>
            <div id="details">
                <ul>
                    <li><span>{issue}</span></li>
                </ul>
            </div>
        </div>
    )
}
